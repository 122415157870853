import React from 'react';
import ReactDOM from 'react-dom/client';
import AppManager from './AppManager';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <AppManager />
    </React.StrictMode>
)