import type { ApexOptions } from 'apexcharts'

import Chart from 'react-apexcharts'

import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material'

import { useLangage } from '../../AppProvider'

type UserStatusRepartPieChartsProps = {
    datas?: { active: number, inactive: number } | undefined
    legendPosition?: 'top' | 'bottom' | 'left' | 'right'
    height?: number
}
export const UserStatusRepartPieCharts = ({
    datas,
    legendPosition = 'bottom',
    height
}: UserStatusRepartPieChartsProps) => {
    const { trad } = useLangage()
    const theme = useTheme()

    const size = 200; // Taille du graphique
    const strokeWidth = 40; // Largeur du bord du cercle

    if (!datas) return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Skeleton variant="text" width={200} height={30} />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={2}>
                {/* <Skeleton variant="rectangular" /> */}
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" flexDirection={'column'}>
                    <Box position="relative" width={size} height={size}>
                        <Skeleton variant="circular" width={size} height={size} />
                        <Skeleton
                            variant="circular"
                            width={size - strokeWidth * 2}
                            height={size - strokeWidth * 2}
                            style={{
                                position: 'absolute',
                                top: strokeWidth,
                                left: strokeWidth,
                                backgroundColor: 'white', // Couleur du centre vide
                            }}
                        />
                    </Box>
                    <Box ml={2}>
                        <Skeleton variant="text" width={100} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                    </Box>
                </Box>
            </Grid>
        </Grid >
    )

    const series = [datas.active, datas.inactive]
    const options: ApexOptions = {
        chart: {
            type: 'donut',
        },
        colors: [
            theme.palette.primary.main,
            theme.palette.secondary.main,
        ],
        labels: [trad.Active, trad.Inactive],
        legend: {
            position: legendPosition, // Positionne la légende en dessous du graphique
        },

    }
    return (
        <Grid container spacing={2} justifyContent={'space-between'} flexGrow={1}>
            <Grid item>
                <Typography variant="h6">
                    {trad.Distribution_by_status}
                </Typography>
            </Grid>
            <Grid item xs={12} flexGrow={1}>
                <Chart
                    options={options}
                    series={series}
                    type="donut"
                    height={height}
                />
            </Grid>
        </Grid>
    )
}