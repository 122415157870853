import React from 'react'

import { Button, ListItemText, Menu, MenuItem } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

import { useLangage } from '../AppProvider'

type PeriodDataSelectorType = {
    periodIndex: number
    onChange: (periodIndex: number) => void
}
export const PeriodDataSelector = ({
    periodIndex,
    onChange
}: PeriodDataSelectorType) => {
    const { trad } = useLangage()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const periodList = [
        { trad: 'week', days: 7 },
        { trad: 'month', days: 30 },
        { trad: 'quarter', days: 90 },
        { trad: 'year', days: 365 }
    ]
    return (
        <React.Fragment>
            <Button
                onClick={(e) => setAnchorEl(e.currentTarget)}
                endIcon={
                    !Boolean(anchorEl) ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                }
            >
                {trad[periodList[periodIndex].trad]}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {periodList.map((period, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            onChange(index)
                            setAnchorEl(null)
                        }}
                    >
                        <ListItemText primary={trad[period.trad]} />
                    </MenuItem>
                ))}

            </Menu>
        </React.Fragment>
    )
}