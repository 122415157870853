import type { ApexOptions } from 'apexcharts'

import Chart from 'react-apexcharts'

import { Grid, Skeleton, Typography, useTheme } from '@mui/material'
import { useLangage } from '../../AppProvider'

type UserRepartChartsProps = {
    datas?: { name: { [k: string]: string }, nb: number }[]
    height?: number
}
export const UserRepartCharts = ({
    datas,
    height
}: UserRepartChartsProps) => {
    const theme = useTheme()
    const { locale, trad } = useLangage()

    if (!datas) return (
        <Grid container spacing={2} m={2}>
            <Grid item xs={4}>
                <Skeleton variant="text" height={30} />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={2}>
                <Skeleton variant="text" height={30} />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
                {Array.from({ length: 3 }).map((_, index) => (
                    <Grid container alignItems="center" spacing={2} key={index} mb={2}>
                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'right' }}>
                            <Skeleton variant="text" width={`${Math.random() * 50 + 20}%`} height={20} />
                        </Grid>
                        <Grid item xs={9}>
                            <Skeleton variant="rectangular" width={`${Math.random() * 80 + 20}%`} height={20} />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )

    const usersNumber: number = datas.reduce((acc, d) => acc + d.nb, 0)

    const series = [{
        name: '',
        data: datas.map(d => d.nb)
    }]
    const options: ApexOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                tools: {
                    download: false
                }
            }
        },

        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            }
        },
        colors: [
            theme.palette.primary.main
        ],
        dataLabels: {
            enabled: true,
        },
        xaxis: {
            axisTicks: {
                show: false
            },
            categories: datas.map(d => d.name[locale as any]),
            labels: {
                show: false
            }
        },
        tooltip: {
            enabled: false
        }
    }

    return (
        <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item>
                <Typography variant="h6">
                    {trad.Distribution_by_function}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h6" color={'primary'}>
                    {usersNumber} {usersNumber > 1 ? trad.users : trad.user}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height={height}
                />
            </Grid>
        </Grid>
    )
}