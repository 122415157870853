import type { AppProviderProps, DocumentLike, LocaleType, WebSocketMessage, returnPayloadType } from ".";

import React from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { CssBaseline, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { v4 } from "uuid";

import { AppContext, Page404, clearStore, darkTheme, get, lightTheme, openDatabase, patch } from "."

import { Loader } from "../commonsWS";

const UI = React.lazy(() => import('./UI'));

const Profil = React.lazy(() => import('./Components/Profil'))
const FavoritesList = React.lazy(() => import('./Components/FavoritesList'))

const Login = React.lazy(() => import('./Components/Security/Login'));
const PasswordForgotten = React.lazy(() => import('./Components/Security/PasswordForgotten'));
const ResetPassword = React.lazy(() => import('./Components/Security/ResetPassword'));

const MAX_RECONNECT_ATTEMPTS = 15; // Nombre maximum de tentatives de reconnexion
const RECONNECT_INTERVAL = 1000; // Délai initial entre les tentatives de reconnexion en millisecondes
const MAX_RECONNECT_INTERVAL = 30000; // Délai maximum entre les tentatives de reconnexion en millisecondes

const isExpired = (token: string) => {
    const payload = JSON.parse(atob(token.split('.')[1]))
    return payload.exp * 1000 < Date.now()
}


/**
 * AppProvider component is a higher-order component that provides various context values and functionality to its children components.
 *
 * @component
 * @example
 * return (
 *   <AppProvider routes={routes}>
 *     <App />
 *   </AppProvider>
 * )
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @param {Array} props.routes - The routes configuration.
 * @returns {React.ReactNode} The rendered component.
 */
export const AppProvider: React.FC<AppProviderProps> = ({ routes, children, app }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dbRef = React.useRef<IDBDatabase | null>(null)
    const wsRef = React.useRef<WebSocket | null>(null)
    const messageCallbacks = React.useRef<Map<string, (payload: any) => void>>(new Map());
    const messageSended = React.useRef<Map<string, WebSocketMessage>>(new Map());
    const [reconnectAttempts, setReconnectAttempts] = React.useState<number>(0);
    const [reconnectTimeout, setReconnectTimeout] = React.useState<number | null>(null);
    const [tokens, setTokens] = React.useState<{ accessToken: string | undefined, refreshToken: string | undefined }>({ accessToken: undefined, refreshToken: undefined });
    const [user, setUser] = React.useState<any>(null);
    const [locale, setLocale] = React.useState<LocaleType>(((navigator.language ?? 'fr-FR').length > 2 ? navigator.language : `${navigator.language}-${navigator.language.toUpperCase()}`) as LocaleType);
    const [translations, setTranslations] = React.useState<{ [k: string]: { [k: string]: string } }>({});
    const [theme, setTheme] = React.useState(lightTheme())
    const [uiOpen, toggleUiOpen] = React.useReducer(open => !open, false)
    const [searchValue, setSearchValue] = React.useState<string>('')
    const [searchResults, setSearchResults] = React.useState<DocumentLike[] | null>(null)
    const [overflowY, setOverflowY] = React.useState<boolean>(false)
    const [zIndex, setZIndex] = React.useState<1100 | 'unset'>(1100)
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));


    const isReady = React.useMemo(() =>
        wsRef.current !== null &&
        dbRef.current !== null &&
        Object.keys(translations).length > 0 &&
        ((tokens.accessToken !== undefined && user !== null) || tokens.accessToken === undefined)
        , [wsRef.current, dbRef.current, translations, tokens, user]);

    const sendFile = React.useCallback(async (controller: WebSocketMessage['controller'], field: string, file: File, id: string): Promise<returnPayloadType> => {
        if (!wsRef.current) return Promise.reject()

        const metadatas = { mime: file.type, size: file.size, name: file.name, updatedAt: file.lastModified }

        const responseFileEntity = await new Promise<returnPayloadType>((resolve, reject) => {
            const uuid = v4()
            messageCallbacks.current.set(uuid, resolve);
            wsRef.current?.send(JSON.stringify({ controller: 'File', action: 'create', payload: { ...metadatas }, uuid }))
        })

        if (responseFileEntity.wsCode !== 201) return Promise.reject()
        const entityFile = responseFileEntity.payload
        const responseUpload = await new Promise<boolean>((resolve, reject) => {

            const CHUNK_SIZE = 1024 * 16; // 16 KB
            const reader = new FileReader();

            let offset = 0;

            reader.onload = () => {
                const arrayBuffer = reader.result as ArrayBuffer;
                const base64Chunk = btoa(
                    new Uint8Array(arrayBuffer)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );

                const isLastChunk = offset + CHUNK_SIZE >= file.size;
                const payload = {
                    type: 'chunk',
                    base64: base64Chunk,
                    isLastChunk
                };

                wsRef.current?.send(JSON.stringify({ controller: 'File', action: 'file', payload, id: entityFile.id }))
                offset += CHUNK_SIZE;


                if (!isLastChunk) {
                    readNextChunk();
                } else {
                    resolve(true)
                }

            };

            reader.onerror = (error) => {
                console.error('Error reading file:', error);
                reject(undefined)
            };

            const readNextChunk = () => {
                const slice = file.slice(offset, offset + CHUNK_SIZE);
                reader.readAsArrayBuffer(slice);
            };

            readNextChunk();

        })

        if (!responseUpload) return Promise.reject()

        return new Promise<returnPayloadType>((resolve, reject) => {
            const uuid = v4()
            messageCallbacks.current.set(uuid, resolve);
            if (['documentId', 'formationId'].includes(field)) {
                wsRef.current?.send(JSON.stringify({ controller, action: 'update', payload: { [field]: id }, id: entityFile.id, uuid }))
            } else {
                wsRef.current?.send(JSON.stringify({ controller, action: 'update', payload: { [field]: entityFile.id }, id, uuid }))
            }
        })

    }, [wsRef.current]);

    const sendMessage = React.useCallback(async (controller: WebSocketMessage['controller'], action: WebSocketMessage['action'], payload?: any, id?: string): Promise<returnPayloadType> => {
        if (!wsRef.current) return Promise.reject('no ws')

        return new Promise<returnPayloadType>((resolve, reject) => {
            const uuid = v4();

            messageCallbacks.current.set(uuid, resolve);
            messageSended.current.set(uuid, { controller, action, payload, id } as WebSocketMessage)

            wsRef.current?.send(JSON.stringify({ uuid, controller, action, payload, id }))
        })

    }, [wsRef.current]);



    const langage = React.useMemo(() => ({
        trad: translations[locale] ?? {},
        locale,
        locales: Object.keys(translations) as LocaleType[],
        fullLocales: translations,
        changeLocale: (locale: LocaleType) => {
            sendMessage('ConnectedUser', 'update', { locale })
            setLocale(locale)
        }
    }), [translations, locale])

    const routage = React.useMemo(() => ({
        navigate,
        location,
        routes
    }), [navigate, routes])

    const search = React.useMemo(() => ({
        search: searchValue,
        setSearch: setSearchValue,
        handleSearch: () => sendMessage('Search', 'list', { searchValue }),
        results: searchResults,
        setResults: setSearchResults
    }), [searchValue, setSearchValue, sendMessage, searchResults, setSearchResults])

    const security = React.useMemo(() => ({
        login: async (email: string, password: string) => await sendMessage('Security', 'login', { email, password }),
        refresh: async () => sendMessage('Security', 'refresh', { refreshToken: tokens.refreshToken }),
        sendRecoveryEmail: async (email: string) => await sendMessage('Security', 'send-recover-password-email', { email }),
        changePassword: async (password: string, token: string) => await sendMessage('Security', 'change-password', { password, token }),
        logout: () => {
            setTokens({ accessToken: undefined, refreshToken: undefined })
            clearStore(dbRef.current, 'tokens')
            setUser(null)
            navigate('/login')
        },
    }), [sendMessage, setTokens])

    const ui = {
        theme,
        setTheme: (themeName: 'dark' | 'light' | null | ((t: 'dark' | 'light') => 'dark' | 'light'), uiSize: number | undefined = 14) => {
            if (typeof themeName === 'function') themeName = themeName(theme.palette.mode)
            if (themeName) sendMessage('ConnectedUser', 'update', { theme: themeName })
            if (uiSize) sendMessage('ConnectedUser', 'update', { uiSize })
            if (themeName === null) themeName = theme.palette.mode
            if (uiSize === undefined || uiSize === null) uiSize = theme.typography.fontSize
            console.log('debug', themeName, uiSize)
            setTheme(themeName === 'dark' ? darkTheme(uiSize) : lightTheme(uiSize))
        },
        drawerWidth: 400,
        open: uiOpen,
        toggleOpen: toggleUiOpen,
        overflowY,
        setOverflowY,
        zIndex,
        setZIndex,
        isMdUp
    }

    const connectWebSocket = React.useMemo(() => () => {
        if (wsRef.current?.readyState === WebSocket.CONNECTING) return;
        if (wsRef.current?.readyState === WebSocket.OPEN) return;
        let uri = (process.env.NODE_ENV === 'production' ? `wss://${app ? app + '.' : ''}evidence101.eu` : `ws://${app ? app + '.' : ''}localhost:3100`) + '?lang=' + locale;
        if (tokens.accessToken !== undefined && tokens.refreshToken !== undefined) {
            if (isExpired(tokens.accessToken)) {
                uri += '&refreshToken=' + tokens.refreshToken
            } else {
                uri += '&accessToken=' + tokens.accessToken
            }
        }

        const websocket = new WebSocket(uri);
        // const websocket = new WebSocket(`${process.env.NODE_ENV === 'production' ? 'wss://evidence101.eu' : 'ws://localhost:3100'}?token=${tokens?.accessToken ?? ''}&lang=${locale}`);
        const uuid = v4();
        websocket.onopen = () => {
            console.log('Connected', uuid);
            setReconnectAttempts(0); // Réinitialiser les tentatives de reconnexion
            if (reconnectTimeout) {
                clearTimeout(reconnectTimeout);
                setReconnectTimeout(null);
            }
            if (messageSended.current.size > 0) {
                messageSended.current.forEach((message, uuid) => {
                    websocket.send(JSON.stringify({ uuid, ...message }))
                })
            }
        }
        websocket.onclose = (ev: CloseEvent) => {
            console.log('Disconnected', ev.code, ev.reason);
            if (ev.code === 1008) console.log(1008, ev.reason)
            if (ev.code === 1008) security.logout()
            handleWebSocketClose();
        }
        websocket.onmessage = async (event: MessageEvent) => {
            try {
                const message = JSON.parse(event.data)
                if (message.wsCode === 401) {
                    await security.refresh()
                }

                if (message.originalUuid) {
                    const callback = messageCallbacks.current.get(message.originalUuid);
                    if (callback) {
                        callback(message);
                        messageCallbacks.current.delete(message.originalUuid);
                        messageSended.current.delete(message.originalUuid);
                    }
                }

                // APP LISTENERS

                if (message.controller === 'Search' && message.action === 'list' && message.wsCode === 200) {
                    setSearchResults(message.payload)
                }
                if (message.controller === 'Security' && (message.action === 'login' || message.action === 'refresh')) {
                    if (message.wsCode === 200) {
                        setTokens({ accessToken: message.payload.accessToken, refreshToken: message.payload.refreshToken })
                        if (message.action === 'login') navigate('/')
                    }
                }
                if (message.controller === 'ConnectedUser' && message.action === 'update' && message.wsCode === 200) {
                    setUser(message.payload)
                }
                if (message.controller === 'Translate' && message.action === 'list' && message.wsCode === 200) {
                    setTranslations((prev) => ({
                        ...prev,
                        ...message.payload
                    }));
                }
            } catch (e) {
                console.log('Message received: ', event.data);
            }
        }

        wsRef.current = websocket;

    }, [tokens?.accessToken, reconnectTimeout, setTranslations]);

    const handleWebSocketClose = React.useCallback(() => {
        if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
            const delay = Math.min(RECONNECT_INTERVAL * Math.pow(2, reconnectAttempts), MAX_RECONNECT_INTERVAL);
            const timeout = window.setTimeout(connectWebSocket, delay);
            setReconnectTimeout(prev => { if (prev) clearTimeout(prev); return timeout });
            setReconnectAttempts(prev => prev + 1);
        } else {
            console.log('Nombre maximum de tentatives de reconnexion atteint.');
        }
    }, [reconnectAttempts, setReconnectAttempts, connectWebSocket, reconnectTimeout]);

    React.useEffect(() => {
        if (user) {
            if (user.locale && user.locale !== locale) {
                setLocale(user.locale)
            }
            if (user.theme || user.uiSize) {
                if (user.theme && user.theme !== theme.palette.mode) ui.setTheme(user.theme, user.uiSize)
                console.log(user.theme, user.uiSize)
            }
        }
    }, [user])

    React.useEffect(() => {
        let timeout: NodeJS.Timer | null = null;
        let interval: NodeJS.Timer | null = null;

        if (wsRef.current && wsRef.current.readyState !== WebSocket.CLOSED) wsRef.current?.close(1000);


        /**
         * Calculates the remaining time until a token expires.
         * @param token - The token to extract the expiration time from.
         * @returns The remaining time in milliseconds until the token expires.
         */
        const expiredIn = (token: string) => {
            const payload = JSON.parse(atob(token.split('.')[1]))
            return payload.exp * 1000 - Date.now()
        }


        if (wsRef.current) connectWebSocket()
        if (tokens.accessToken && tokens.refreshToken) {
            get<{ id: 'credentials', accessToken: string | undefined, refreshToken: string | undefined } | null>(dbRef.current, 'tokens', 'credentials').then(credentials => {
                if (!credentials || credentials.accessToken !== tokens.accessToken || credentials.refreshToken !== tokens.refreshToken) {
                    patch(dbRef.current, 'tokens', { id: 'credentials', ...tokens })
                }
            })

            if (expiredIn(tokens.accessToken) < 5000) {
                security.refresh()
            } else {
                timeout = setTimeout(security.refresh, expiredIn(tokens.accessToken) - 5000)
            }

        } else {
            clearStore(dbRef.current, 'tokens')
        }
        if (tokens.accessToken)
            interval = setInterval(() => {
                if (tokens.accessToken) {
                    const expiredInSec = expiredIn(tokens.accessToken) / 1000
                    const expiredInMin = expiredInSec / 60
                    if (expiredInMin > 1) console.log(`${Math.round(expiredInMin)} minutes before token expires`)
                    else console.log(`${expiredInSec} seconds before token expires`)
                }
            }, expiredIn(tokens.accessToken) / (1000 * 15) > 1 ? 15000 : 1000)
        return () => {
            if (timeout) clearTimeout(timeout);
            if (interval) clearInterval(interval);
        }
    }, [tokens]);

    React.useEffect(() => {
        openDatabase([
            { store: 'tokens', version: 1 }
        ]).then(db => dbRef.current = db)
            .then(async () => {
                const credentials = await get<{ id: 'credentials', accessToken: string | undefined, refreshToken: string | undefined } | null>(dbRef.current, 'tokens', 'credentials')
                if (credentials) {
                    setTokens({ accessToken: credentials.accessToken, refreshToken: credentials.refreshToken })
                }
            })
            .finally(() => {
                connectWebSocket()
            })

        return () => {
            if (wsRef.current) {
                wsRef.current.close(1000);
                console.log('Clearing WebSocket')
            }
            if (reconnectTimeout) {
                console.log('Clearing reconnect timeout');
                clearTimeout(reconnectTimeout);
            }
        };
    }, []);

    React.useEffect(() => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) wsRef.current?.send(JSON.stringify({ controller: 'Spy', payload: { location } }))
    }, [location, wsRef.current, wsRef.current?.readyState, user])

    return (
        <AppContext.Provider value={{
            ws: wsRef.current,
            langage,
            routage,
            search,
            sendMessage,
            sendFile,
            security,
            ui,
            user
        }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {isReady
                    ? <UI routes={routes}>
                        <React.Suspense fallback={<Loader />}>
                            {user
                                ? <Routes>
                                    {routes
                                        .map(route =>
                                            (route !== 'sep' && <Route key={route.url} path={route.url} element={<route.component />} />) ?? <></>)}
                                    <Route path={'/profil'} element={<Profil />} />
                                    <Route path={'/favorites'} element={<FavoritesList />} />
                                    <Route path='*' element={<Page404 />} />
                                </Routes>
                                : <Routes>
                                    <Route path='/password-reset/:token' element={<ResetPassword />} />
                                    <Route path='/password-reset' element={<PasswordForgotten />} />
                                    <Route path='*' element={<Login />} />
                                </Routes>}
                        </React.Suspense>

                    </UI>
                    : <Loader />}
            </ThemeProvider>
        </AppContext.Provider>
    )
}