import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Home as HomeIcon } from "@mui/icons-material";

import { AppProvider } from "../AppProvider";

const Home = React.lazy(() => import('./Components/Landing'))
const Service = React.lazy(() => import('./Components/Service'))


const AppManager = () => {
    return (
        <BrowserRouter>
            <AppProvider routes={[
                { trad: 'home', url: '/', component: Home, isLeft: true, icon: HomeIcon },
                { trad: 'serviceAdminTitle', url: '/service/:id', component: Service, icon: HomeIcon }
            ]} />
        </BrowserRouter>
    )
}

export default AppManager;