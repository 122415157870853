import type { ApexOptions } from 'apexcharts'

import Chart from 'react-apexcharts'

import { Grid, Typography, useTheme } from '@mui/material'
import { Loader } from '../Loader'
import { useLangage } from '../../AppProvider'
import React from 'react'
import { PeriodDataSelector } from '..'

type TimeProgressChartsProps = {
    datas?: { date: string, nb: number }[][]
    xAxisAnnotations?: { date: string, label: string }[]
    yFormatter?: (value: number) => string
    xFormatter?: (value: number) => string
    height?: number
}
export const TimeProgressCharts = ({
    datas,
    xAxisAnnotations,
    xFormatter,
    yFormatter,
    height
}: TimeProgressChartsProps) => {
    const theme = useTheme()
    const { trad } = useLangage()

    const [periodIndex, setPeriodIndex] = React.useState(0)

    if (!datas) return <Loader />


    const series = [{
        name: "",
        data: datas[periodIndex].map(d => ({ x: new Date(d.date).getTime(), y: d.nb }))
    }]
    const options: ApexOptions = {
        chart: {
            type: 'line',
            toolbar: {
                show: false
            }
        },
        annotations: {
            xaxis: xAxisAnnotations
                ? xAxisAnnotations.map(a => ({
                    x: new Date(a.date).getTime(),
                    strokeDashArray: 0,
                    borderColor: '#775DD0',
                    label: {
                        borderColor: '#775DD0',
                        style: {
                            color: '#fff',
                            background: theme.palette.info.main,//'#775DD0',
                        },
                        text: a.label,
                    }
                }))
                : [],
        },
        colors: [
            theme.palette.primary.main
        ],
        dataLabels: {
            enabled: false,
            formatter: xFormatter ?? function (val: any) {
                return Math.round(val * 10) / 10 + '%';
            }
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        markers: {
            size: 0
        },
        xaxis: {
            type: 'datetime',
            labels: {
                show: false,
                // formatter(value, timestamp, opts) {
                //     const date = new Intl.DateTimeFormat(actualLocale, { month: 'short', day: 'numeric', year: '2-digit' }).format(new Date(value))
                //     console.log(value, date, opts)
                //     return date
                // },
            }
        },
        yaxis: {
            min: 0,
            max: Math.round(
                Math.max(
                    ...datas[periodIndex].map(d => d.nb / 10)
                ) * 10
            ), // 100,
            stepSize: 10,
            labels: {
                formatter: yFormatter ?? (val => Math.round(val * 10) / 10 + '%')
            }
        },
    }

    return (
        <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item>
                <Typography variant="h6">
                    {trad.Activity_on_Evidence}
                </Typography>
            </Grid>
            <Grid item>
                <PeriodDataSelector periodIndex={periodIndex} onChange={setPeriodIndex} />
            </Grid>
            <Grid item xs={12}>
                <Chart
                    options={options}
                    series={series}
                    type="line"
                    height={height}
                />
            </Grid>
        </Grid>
    )
}